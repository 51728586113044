<template>
  <div class="height100">
    <a-spin v-if="loading" size="large" class="height100 apin" />
    <!-- 时候三级页签由上控制 -->
    <a-tabs v-if="isShowTabs" type="card" :activeKey="activeTabsKey" :style="{ width: '100%' }"
      class="mrj-layout-tabs asasas" :class="istabsShow ? 'mrj-layout-tabs_hide' : ''" @change="handleTabsClick"
      v-model="activeTabsKey">
      <template v-for="it in menuList">
        <a-tab-pane class="add" v-if="true" :key="it.title">
          <span slot="tab">
            <span class="inline-block" :class="activeTabsKey == it.title ? 'tabsCircleA' : 'tabsCircle'" :style="{
              width: '3px',
              height: '3px',
              marginBottom: '2.8px'
            }"></span>
            {{ it.title }}
          </span>
        </a-tab-pane>
      </template>
    </a-tabs>
    <!-- 详情页 -->
    <div class="detail-breadcrumb" v-if="isShowBread">
      <template v-for="(item, index) in breadcrumbList">
        <div class="breadcrumb-item canClick" v-if="index === 0" :key="index" @click="goBack(item)">
          <div class="arrow">
            <a-icon class="icon" type="left" />
          </div>
          <span>{{ item.text }}</span>
        </div>
        <div class="breadcrumb-item" v-if="index > 0" :key="index">
          /<span style="margin-left: 5px" :class="{ 'canClick': item.backpage }">{{ item.text }}</span>
        </div>
      </template>
    </div>
    <micro-app class="microAppBox" v-if="isShowMicro" :name='microName' :url='routeUrl' :baseroute='baseroute'
      @created='created' @beforemount='beforemount' @mounted='mounted' @unmount='unmount' @error='error'
      :data="dataForChild" @datachange="datachange"></micro-app>
    <div class="height100" v-else>
      <Iframe> </Iframe>
    </div>
  </div>
</template>
<script>
  import obj from './config'
  import storage from 'store'
  import {
    MrjMenu,
    MrjTab,
    NavHeader
  } from '@/components'
  import Iframe from "./iframe"
  import microApp from '@micro-zoe/micro-app'
  import Msg from '@/components/mrj-menu/msg'


  export default {
    name: 'layout-micro',
    components: {
      NavHeader,
      MrjTab,
      MrjMenu,
      Iframe
    },
    data() {
      return {
        breadcrumbList: [{
          text: '退款审批'
        }, {
          text: '详情'
        }],
        isShowBread: false,
        baseroute: '/headquarters',
        activeTabsKey: 0, //三级页签
        isShowTabs: false,
        menuList: [],
        istabsShow: false,
        // routeUrl: 'https://sandbox.system.meirenji.vip/partner/',
        // routeUrl: 'http://192.168.3.60:8888/',
        routeUrl: "",
        dataForChild: {},
        loading: false,
        // brand_id: process.env.VUE_APP_API_BASE_URL != 'https://sg.shenglisige.com' ? '15' : '18',
        brand_id: 18,
        isShowMicro: true,
        microName: 'app1',
      }
    },
    created() {
      // 緩存需要的數據
      let seconderyMenuObj = storage.get('seconderyMenuObj2')
      let thirdMenuObj = storage.get('thirdMenuObj') || ''
      // debugger
      if (thirdMenuObj && thirdMenuObj.title && seconderyMenuObj && seconderyMenuObj.title != "合伙人收益") {
        this.routeUrl = thirdMenuObj.url
        this.isShowTabs = true
        this.activeTabsKey = thirdMenuObj.title
        let menuObj = storage.get('seconderyMenuObj2').menu_menuBar ? storage.get('seconderyMenuObj2').menu_menuBar :
          storage.get('seconderyMenuObj2').children ? storage.get('seconderyMenuObj2').children : storage.get(
            'seconderyMenuObj2').menu_children ? storage.get('seconderyMenuObj2').menu_children : ''
        this.menuList = menuObj
      } else if (seconderyMenuObj.title == '合伙人收益') {
        this.isShowTabs = true
        this.menuList = seconderyMenuObj.children
        if (thirdMenuObj && thirdMenuObj.title) {
          this.activeTabsKey = thirdMenuObj.title
        } else {
          this.activeTabsKey = seconderyMenuObj.children[0].title
        }
        let str =
          '{ "id": 21, "pid": 16, "title": "合伙人收益", "icon": "meiye-routeicon-data-view", "path": "partnerMenuEarnings", "component": "partnerMenuEarnings", "params": "", "children": [{ "id": 24, "pid": 21, "title": "合伙人佣金", "icon": "", "path": "commission", "component": "commission", "params": "", "fullpath": "/partner/partnerMenuEarnings/commission", "fullPath": "/partner/partnerMenuEarnings/commission" }, { "id": 25, "pid": 21, "title": "合伙人分红", "icon": "", "path": "bonus", "component": "bonus", "params": "", "fullpath": "/partner/partnerMenuEarnings/bonus", "fullPath": "/partner/partnerMenuEarnings/bonus" }], "fullpath": "/partner/partnerMenuEarnings" }'
        localStorage.setItem('seconderyMenuObj', str)
      }
      if (seconderyMenuObj.title != '合伙人收益') {
        if (this.$route.path.includes('reportForms') || storage.get('secondery')?.menu_title == '合伙人') {
          storage.set('seconderyMenuObj', storage.get('seconderyMenuObj2'))
        }
      }
      if (this.$route.path.includes('main-part')) {
        this.baseroute = '/main-part'
      }
      if (this.$route.path.includes('brand/store/index')) {
        this.baseroute = '/brand'
      }
      if (this.$route.path.includes('systemSetting/employeeManage')) {
        this.baseroute = '/systemSetting'
      }
      if (this.$route.path.includes('business-app-operation')) {
        this.baseroute = '/business-app-operation'
      }
      if (this.$route.path.includes('partner')) {
        // this.baseroute = '/partner'
        this.baseroute = '/'
      }
      if (this.$route.path.includes('qyk')) {
        this.baseroute = '/qyk'
        this.menuList = storage.get('seconderyMenuObj2').menu_menuBar || storage.get('seconderyMenuObj2').children
      }
      storage.set('headerShow', 2)
      this.dataForChild = {
        headerShow: 2,
        hiddenTab: 2,
        txt: '总部后台',
        AccessToken: storage.get('Access-Token'),
        global_brand_id: this.brand_id,
        path: storage.get('seconderyMenuObj2') ? storage.get('seconderyMenuObj2').path : storage.get('thirdMenuObj')
          .path,
        query: {
          nick_name: storage.get('seconderyMenuObj2')?.title,
          url: storage.get('seconderyMenuObj2')?.path,
          url_name: storage.get('seconderyMenuObj2')?.url,
          hiddenTab: 2
        },
      }
      // 判断  关怀 教育 跳iframe
      if (this.$route.path == '/headquarters/admin/login' || this.$route.path == '/headquarters/uumlogin/in') {
        this.isShowMicro = false
        return false
      }
      let arr = this.$route.path.split('/')
      let arr1 = []
      arr.forEach((el, idx) => {
        if (idx > 1) {
          arr1.push(el)
        }
      })
      let str = '/' + arr1.join('/')
      str = str.replace('//', '/')
      let source = storage.get('menu_base')
      // 目前只做到三级
      if (this.$route.path.includes('main-part')) {
        str = '/main-part' + str
      }
      if (this.$route.path.includes('store/index')) {
        str = '/brand' + str
      }
      if (this.$route.path.includes('systemSetting/employeeManage')) {
        str = '/systemSetting' + '/employeeManage'
      }
      if (this.$route.path.includes('business-app-operation')) {
        str = '/business-app-operation' + str
      }
      if (this.$route.path.includes('partner')) {
        str = storage.get('thirdMenuObj') ? storage.get('thirdMenuObj').path : storage.get('seconderyMenuObj2') ?
          storage.get('seconderyMenuObj2').path : str
        str = '/' + str
        str = str.replace('//', '/')
      }
      if (this.$route.path.includes('qyk')) {
        str = '/qyk' + str
        str = str.replace('/qyk/qyk/', '/qyk/')
      }
      let titles = storage.get('global_menu_obj').title || storage.get('global_menu_obj').menu_title
      if (titles == '合伙人') {
        this.microName = 'hehuoren'
        // this.routeUrl = 'http://192.168.2.109:8888/'
      }
      if (str == '/managecenter/list' && !thirdMenuObj && titles != '裂变派送') {
        // 只有卡券中心和裂变派送是一样的
        // debugger
        this.routeUrl = this.$route.query.url || storage.get('seconderyMenuObj2').url
        this.isShowTabs = true
        this.menuList = seconderyMenuObj.children || seconderyMenuObj.menu_menuBar || seconderyMenuObj.menu_children
        storage.set('thirdMenuObj', this.menuList[0])
        this.activeTabsKey = this.menuList.length ? this.menuList[0].title : ''
      } else if (thirdMenuObj.title) {
        console.log(this.routeUrl, '?routeUrl');
        if (!this.routeUrl) {
          this.routeUrl = this.$route.query.url || storage.get('seconderyMenuObj2').url
          // this.routeUrl = 'http://192.168.2.109:8888/'
        }
        if (titles == '合伙人') {
          this.microName = 'hehuoren'
        }
        return false
      } else if (titles == '裂变派送') {
        this.routeUrl = this.$route.query.url || storage.get('seconderyMenuObj2').url
        return false
      } else {
        this.treeRecursion(source, str)
      }
      // 拆名字 绩效
      if (titles == '财税') {
        this.microName = 'caishui'

        // 财税的详情
      }
      if (titles == '绩效' || titles == '产品') {
        this.microName = 'tabsnone'
      }
      if (titles == '组织') {
        this.microName = 'zuzhi'
      }
      if (titles == '设置') {
        this.microName = 'shezhi'
      }
      if (titles == '报表') {
        this.microName = 'baobiao'
      }
      if (titles == '绩效') {
        this.microName = 'jixiao'
      }
      if (titles == '会员中心') {
        this.microName = 'huiyuanzhongxin'
      }
      if (titles == '运营中心') {
        this.microName = 'yunyingzhongxin'
      }
      if (titles == '产品') {
        this.microName = 'chanpin'
      }
      if (titles == '财税' && seconderyMenuObj.title.includes('支付设置')) {
        this.microName = 'zhifuzhezhi'
      }

      console.log(this.routeUrl, '?routeUrl');
      if (!this.routeUrl) {
        this.routeUrl = this.$route.query.url || storage.get('seconderyMenuObj2').url
      }
      // if (seconderyMenuObj.title.includes('员工业绩')) {
      //   this.routeUrl = 'http://192.168.3.27:9090/kpi/'
      // }
      // if (seconderyMenuObj.title.includes('退款审批')) {
      //   this.routeUrl = 'http://192.168.2.109:8000/fms/'
      // }
      // if (seconderyMenuObj.title.includes('支付设置')) {
      //   this.routeUrl = 'http://192.168.10.2:9090/'
      // } // this.routeUrl = 'http://192.168.2.109:8888/'
      if (seconderyMenuObj.title.includes('合伙人')) {
        storage.set('seconderyMenuObj', storage.get('seconderyMenuObj2'))
        // this.routeUrl = 'http://192.168.2.109:8888/'
      }
    },
    methods: {
      // 递归组件 找到某一项 给值
      treeRecursion(data, str) {
        data.forEach(el => {
          // 把数字类型转为字符串
          if (('/' + el.path) == str) {
            this.routeUrl = el.url
          }
          if (el.children && el.children.length) this.treeRecursion(el.children, str);
        })
      },
      created() {
        console.log('micro-app元素被创建')
        // debugger
        this.loading = true

      },
      beforemount() {
        // debugger
        console.log('即将被渲染')
      },
      mounted() {
        console.log('已经渲染完成')
        // debugger
        console.log(this.$route.meta);
        this.loading = false
        if (storage.get('seconderyMenuObj2').title == '员工管理') {
          setTimeout(() => {
            document.querySelector('#app .ant-table .ant-table-body').style.marginTop = '0px'
            document.querySelector('.microAppBox').style.height = 'calc(100% - 12px)'
          }, 100)
        }
        if (storage.get('global_menu_obj').menu_title == '财税') {
          setTimeout(() => {
            if (storage.get('seconderyMenuObj2').title == '退款审批') {
              document.querySelector('.microAppBox').style.margin = '0px'
            } else {
              document.querySelector('.microAppBox').style.margin = '14px 12px 0 8px'

            }
            document.querySelector('.microAppBox').style.paddingBottom = '30px'
            document.querySelector('.microAppBox .ant-pagination').style.marginTop = '20px'
            document.querySelector('.microAppBox .ant-pagination').style.borderTop = 'none'
          }, 200)
        }
        if (storage.get('global_menu_obj').menu_title == '组织') {
          setTimeout(() => {
            document.querySelector('.microAppBox').style.paddingBottom = '20px'
          }, 100)
        }
        if (storage.get('global_menu_obj').menu_title == '合伙人') {
          setTimeout(() => {
            document.querySelector('.microAppBox .page-list').style.height = 'calc(100vh - 136px)'
            document.querySelector('.microAppBox .page-list').style.margin = '18px'
          }, 100)
        }
        if (storage.get('global_menu_obj').menu_title == '报表') {
          setTimeout(() => {
            document.querySelector('.microAppBox .page-list').style.height = 'calc(100vh - 86px)'
            // document.querySelector('.microAppBox .ant-table-body').style.maxHeight = '413px'
            //树形结构样式
            document.querySelector('.navTree').style.margin = '18px 0px 0 18px'
            document.querySelector('.navTree').style.height = 'calc(100vh - 86px)'
            if (storage.get('seconderyMenuObj2').title == '门店使用系统统计') {
              document.querySelector('.microAppBox .page-wrap').style.height = 'calc(100vh - 70px)'
            }
          }, 300)
        }
        if (storage.get('global_menu_obj').menu_title == '卡券中心') { //menu_title
          setTimeout(() => {
            document.querySelector('.microAppBox').style.height = 'calc(100% - 68px)';
            document.querySelector('.microAppBox .ant-spin-nested-loading').style.minHeight = '40px'

            document.querySelector('.microAppBox .ant-table-placeholder').style.top = '56px'
            document.querySelector('.microAppBox .table-manage .ant-table-tbody tr:last-child td').style
              .borderBottom = '1px solid @listbodylineColor'

          }, 300)
        }
        if (storage.get('global_menu_obj').menu_title == '产品') {
          setTimeout(() => {
            document.querySelector('.microAppBox').style.height = 'calc(100% - 12px)';
          }, 100)
        }
        if (storage.get('global_menu_obj').menu_title == '会员中心') {
          setTimeout(() => {
            document.querySelector('.microAppBox').style.paddingBottom = '18px';
            document.querySelector('.microAppBox::-webkit-scrollbar').style.background = 'transparent';
          }, 100)
        }
        if (storage.get('global_menu_obj').menu_title == '设置') {
          setTimeout(() => {
            document.querySelector('.microAppBox').style.height = 'calc(100% - 12px)';
          }, 100)
        }
        if (storage.get('global_menu_obj').menu_title == '运营中心') {
          setTimeout(() => {
            document.querySelector('.microAppBox').style.height = 'calc(100% - 12px)';
          }, 100)
        }
      },
      unmount() {
        console.log('已经卸载')
      },
      error() {
        console.log('渲染出错')
      },
      handleTabsClick(e) {
        // debugger
        console.log(e);
        let objUrl = ''
        this.menuList.forEach((el, idx) => {
          if (el.title == e) {
            objUrl = el
          }
        })
        this.routeUrl = objUrl.url
        storage.set('thirdMenuObj', objUrl)
        if (storage.get('seconderyMenuObj2').title == '权益卡') {
          path = 'qyk/' + objUrl.path
          path = path.replace(/\/qyk/g, '')
          path = path.replace(/qyk/g, '')
          this.$router.replace({
            path: '/qyk' + path
          })
        } else if (storage.get('seconderyMenuObj2').title == '合伙人收益') {
          path = 'partner/' + objUrl.path
          path = path.replace(/\/partner/g, '')
          path = path.replace(/partner/g, '')
          this.$router.replace({
            path: '/partner/' + path
          })
          storage.set('activeTabsKey', e)

        }
        let path = 'headquarters/' + objUrl.path
        // path = replaceAll('/headquarters/', '/')
        path = path.replace(/\/headquarters/g, '')
        path = path.replace(/headquarters/g, '')
        if (storage.get('seconderyMenuObj2') && storage.get('seconderyMenuObj2').menu_title == '制券中心') {
          this.$router.push({
            path: '/headquarters/' + path
          })
        }

        Msg.$emit('changeRouterview', true)
      },
      datachange(e) { //财税查看
        // debugger
        console.log(e.detail.data, '////datachange');
        if (e.detail.data.text == '退款审批') {
          ///headquarters/splitAccount/refund-review/center
          console.log(this.$route.path);
          this.$router.replace({
            path: '/headquarters/splitAccount/refund-review/center'
          })
          this.routeUrl = storage.get('seconderyMenuObj2').url
          // 刷新路由
          Msg.$emit('changeRouterview', true)
        }
      },
    },
    computed: {},
    mounted() {

    },
  }
</script>
<style lang="less" scoped>
  .microAppBox {
    // margin-left: 20px;
    // margin-right: 18px;
    // margin-top: 18px;
    // height: calc(100% - 38px);
    overflow: hidden;
    height: 100%;
  }

  .mrj-layout-tabs:not(.default_tabs) {
    // height: 100%;
    // display: table;
    // margin-top: 2px;
    // padding-left: 30px;
    // padding-top: 23px;
    //   height: 49px;
    background: #f4f7f7;

    // height: calc(100vh - 52px);
    // &:not(.ant-tabs-nav) {
    //   border-bottom: 1px solid #D9DDE4;
    // }
    // overflow: auto;
    &::after {
      content: "";
      display: block;
      position: absolute;
      z-index: 0;
      left: 0;
      width: 100%;
      height: 0.8px;
      top: 47.8px;
      background: @navborderColor;
      opacity: 1;
    }

    & /deep/ .ant-tabs-bar // ,

    // & /deep/ .ant-tabs-tab
      {
      height: 49px !important;
      line-height: 49px !important;
      padding: 20px 0 0 30px;
      background: #fff !important;
      margin: 0px;
      border-bottom: 1px solid #d3dedc;
    }

    & /deep/ .ant-tabs-tab {
      height: 29px !important;
      line-height: 29px !important;
      border: none !important;
      background: transparent !important;
      font-size: 15px !important;
    }

    &/deep/.ant-tabs-tab-prev.ant-tabs-tab-arrow-show,
    &/deep/.ant-tabs-tab-next.ant-tabs-tab-arrow-show {
      margin: 0 !important;
      height: 29px !important;
      font-weight: 600 !important;
      line-height: 29px !important;
      border: none !important;
      font-size: 15px !important;
      color: #5b5a62;
      background: transparent !important;
      // padding: 20px 0 0 30px;
      // background: #fff !important;
    }

    & /deep/ .ant-tabs-tab {
      &::before {
        content: "";
        display: block;
        position: absolute;
        top: -2px;
        left: 0;
        right: 0;
        bottom: -0.5px;
        background: url(../assets/images/tabs-unselected-1.png) no-repeat 0 0;
        background-size: 100% 100%;
        opacity: 1;
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        z-index: -2;
        left: 0;
        width: 100%;
        height: 1px;
        bottom: 0px;
        background: rgb(244, 245, 247);
        background-size: 100% 100%;
        opacity: 1;
      }
    }

    & /deep/ .ant-tabs-tab-active {
      color: @baseColor11 !important;
      position: relative;
      z-index: 1;

      &::before {
        content: "";
        display: block;
        position: absolute;
        z-index: -1;
        top: -2px;
        left: 0;
        right: 0;
        bottom: -0.5px;
        background: url(../assets/images/tabs-selected-1.png) no-repeat 0 0;
        background-size: 100% 100%;
        opacity: 1;
      }
    }
  }

  .mrj-layout-tabs_hide {
    &::after {
      content: "";
      display: block;
      position: absolute;
      z-index: 0;
      left: 0;
      width: 100%;
      height: 0px;
      top: 48px;
      background: @navborderColor;
      opacity: 1;
    }
  }

  /deep/ .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
    height: 49px;
  }

  .tabsCircleA {
    background: @navselectedpointColor;
  }

  .tabsCircle {
    background: @navpointColor;
  }

  /deep/.ant-tabs .ant-tabs-top-content,
  .ant-tabs .ant-tabs-bottom-content {
    width: 100%;
    height: 100%;
  }

  /deep/.ant-tabs-tabpane-active {
    height: 100%;
  }

  .apin {
    width: 100%;
    height: 100%;
    margin-top: 200px;
  }

  .detail-breadcrumb {
    width: 100%;
    height: 49px;
    line-height: 50px;
    background: #ffffff;
    border-bottom: 1px solid #d2e6e4;
    font-size: 14px;
    padding: 0 20px;

    .breadcrumb-item {
      display: inline-block;
      color: #2d3835;
      margin-right: 5px;

      .arrow {
        width: 16px;
        height: 16px;
        line-height: 12px;
        border-radius: 2px;
        border: 1px solid #d2e6e4;
        display: inline-block;
        color: #2d3835;
        margin-right: 5px;
        text-align: center;

        .icon {
          font-size: 12px;
          transform: scale(0.8);
        }
      }

      &:hover {
        .arrow {
          color: #0bc7b9;
          border: 1px solid #0bc7b9;
        }
      }
    }

    .canClick {
      cursor: pointer;
      color: #0bc7b9;
    }
  }
</style>