import LayoutUser from '@/layouts/user.vue'
import { LayoutBlock, LayoutBase, LayoutMicro } from '../layouts'

const routes = [
  {
    path: '',
    component: LayoutBlock,
    // component: LayoutBase,
    children: [
      {
        path: '/download',
        name: 'download',
        component: () => import('@/views/user/download.vue'),
        meta: {
          title: '下载'
        }
      },
      {
        path: '/user',
        component: LayoutUser,
        children: [
          {
            path: 'login',
            name: 'login',
            component: () => import('@/views/user/login.vue'),
            meta: {
              title: '登录'
            }
          },
          {
            path: 'reset-password',
            name: 'reset-password',
            component: () => import('@/views/user/reset-password.vue'),
            meta: {
              title: '重置密码'
            }
          },
          {
            path: 'register',
            name: 'register',
            component: () => import('@/views/user/register.vue'),
            meta: {
              title: '注册'
            }
          },
        ]
      },
    ]
  },
  {
    path: '/',
    component: LayoutBase,
    redirect: '/overview',
    children: [
      {
        path: '/overview',
        name: 'overview',
        component: () => import('@/views/overview/index.vue'),
        meta: {
          title: '总览'
        }
      },
      {
        path: '/headquarters/*',
        name: 'headquarters',
        component: LayoutMicro,
        meta: {
          title: '总部标题'
        }
      },
      {
        path: '/main-part/*',
        name: 'main-part',
        component: LayoutMicro,
        meta: {
          title: 'mendian'
        }
      },
      {
        path: '/brand/*',
        name: 'brand',
        component: LayoutMicro,
        meta: {
          title: 'renyuan'
        }
      },
      {
        path: '/systemSetting/*',
        name: 'systemSetting',
        component: LayoutMicro,
        meta: {
          title: 'xitong'
        }
      },
      {
        path: '/business-app-operation/*',
        name: 'business-app-operation',
        component: LayoutMicro,
        meta: {
          title: 'app'
        }
      },
      {
        path: '/partner/*',
        name: 'partner',
        component: LayoutMicro,
        meta: {
          title: 'partner'
        }
      },
      {
        path: '/qyk/*',
        name: 'qyk',
        component: LayoutMicro,
        meta: {
          title: 'qyk'
        }
      },
      {
        path: '/cashManage/*',
        name: 'setting',
        component: LayoutMicro,
        meta: {
          title: '支付设置'
        }
      },
      {
        path: '/search-result/*',
        name: 'setting',
        component: LayoutMicro,
        meta: {
          title: '搜索'
        }
      },
      // {
      //   path: '/base_finance_taxation/*',
      //   name: 'finance_taxation',
      //   component: LayoutMicro,
      //   meta: {
      //     title: '财税'
      //   }
      // },
      // {
      //   path: '/base_performance/*',
      //   name: 'performance',
      //   component: LayoutMicro,
      //   meta: {
      //     title: '绩效'
      //   }
      // },
      // {
      //   path: '/base_report/*',
      //   name: 'report',
      //   component: LayoutMicro,
      //   meta: {
      //     title: '报表'
      //   }
      // },
      // {
      //   path: '/base_care/*',
      //   name: 'care',
      //   component: LayoutMicro,
      //   meta: {
      //     title: '关怀'
      //   }
      // },
      // {
      //   path: '/base_product/*',
      //   name: 'product',
      //   component: LayoutMicro,
      //   meta: {
      //     title: '产品'
      //   }
      // },
      // {
      //   path: '/base_users/*',
      //   name: 'users',
      //   component: LayoutMicro,
      //   meta: {
      //     title: '用户'
      //   }
      // },
      // {
      //   path: '/base_organization/*',
      //   name: 'organization',
      //   component: LayoutMicro,
      //   meta: {
      //     title: '组织'
      //   }
      // },
      // {
      //   path: '/base_education/*',
      //   name: 'education',
      //   component: LayoutMicro,
      //   meta: {
      //     title: '教育'
      //   }
      // },
      // {
      //   path: '/base_partner/*',
      //   name: 'partner',
      //   component: LayoutMicro,
      //   meta: {
      //     title: '合伙人'
      //   }
      // },
      // {
      //   path: '/base_global_operations/*',
      //   name: 'global_operations',
      //   component: LayoutMicro,
      //   meta: {
      //     title: '全域运营'
      //   }
      // },
    ]
  },
  {
    path: '*',
    component: () => import('@/views/common/404.vue'),
    meta: {
      title: '404',
      // 如果是用户模块对 404 的引用，通过这个值自定义样式，改一下背景色
      user: true
    }
  }
]

export default routes
