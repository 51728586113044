<template>
  <a-modal
    :title="pwdModal.title"
    :visible="pwdModal.visible"
    :confirm-loading="pwdModal.confirmLoading"
    :footer="null"
    @cancel="pwdModal.handleCancel('pwdModalForm')"
    :width="750"
  >
    <a-form-model
      ref="pwdModalForm"
      :model="pwdModal.form"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item
        label="手机号"
        prop="phone"
      >
        <span>{{   userInfo.staff_mobile  }}</span>
      </a-form-model-item>
      <br />

      <a-form-model-item
        label="账号"
        prop="username"
      >
        <span>{{  userInfo.staff_realname  }}</span>
      </a-form-model-item>
      <template v-if="isFirst">
        <a-form-model-item
          :rules="[{ required: true, message: '登录密码不能为空' }]"
          :label="'设置登录密码'"
          prop="password"
          required
        >
          <a-input-password
            v-model="pwdModal.form.password"
            placeholder="由字符+数字组成，不小于8位"
            type="password"
            autocomplete="off"
          />
        </a-form-model-item>
        <a-form-model-item
          :rules="[{ required: true, message: '再次输入密码不能为空' }]"
          label="再次输入"
          prop="againPassword"
          required
        >
          <a-input-password
            v-model="pwdModal.form.againPassword"
            placeholder="再次输入密码"
            type="password"
            autocomplete="off"
          />
        </a-form-model-item>
      </template>
      <template v-else>
        <br />
        <a-form-model-item
          :label="'新密码'"
          prop="password"
          :rules="[{ required: true, message: '新密码不能为空' }]"
          required
        >
          <a-input-password
            v-model="pwdModal.form.password"
            placeholder="由字符+数字组成，不小于8位"
            type="password"
            autocomplete="off"
          />
        </a-form-model-item>
        <br />
        <a-form-model-item
          label="再次输入"
          :rules="[{ required: true, message: '请再次输入密码' }]"
          prop="againPassword"
          required
        >
          <a-input-password
            v-model="pwdModal.form.againPassword"
            placeholder="再次输入密码"
            type="password"
            autocomplete="off"
          />
        </a-form-model-item>
        <br />
        <a-form-model-item
          prop="code"
          label="验证码"
          :rules="[{ required: true, message: '验证码不能为空' }]"
          required
        >
          <a-input
            style="box-shandow: none"
            v-model="pwdModal.form.code"
            class="ant-input-handpe-pwd"
            :allow-clear="false"
            type="text"
            placeholder="输入验证码"
          >
            <!-- <a-icon slot="prefix" type="mail" :style="{ color: 'rgba(0,0,0,.25)' }" /> -->
          </a-input>
          <div
            class="flex justify-center getCaptcha cursor-pointer"
            type="link"
            tabindex="-1"
            :disabled="pwdModal.form.smsSendBtn"
            @click.stop.prevent="getCaptcha"
            v-text="(!pwdModal.form.smsSendBtn && '获取验证码') || pwdModal.form.time + ' s'"
          ></div>
        </a-form-model-item>
      </template>
    </a-form-model>
    <div class="ant-modal-confirm-btns right">
      <button
        type="button"
        class="ant-btn"
        @click="pwdModal.handleCancel('pwdModalForm')"
      >取消</button>
      <button
        type="button"
        class="ant-btn ant-btn-primary ml-16"
        @click="pwdModal.handleOk('pwdModalForm')"
      >确定</button>
    </div>
  </a-modal>
</template>

<script>
// import router from '@/router'
// import store from '@/store'
import storage from 'store'
import { modifyPassword, FirstmodifyPassword, getpsSmsCode } from '@/api/user'
export default {
  data() {
    let self = this
    return {
      isFirst: false,
      userInfo: storage.get('staff_info'),
      labelCol: { span: 3 },
      wrapperCol: { span: 21 },
      pwdModal: {
        title: null,
        visible: false,
        confirmLoading: false,
        handleOk: self.handlePwdOk,
        handleCancel: self.handlePwdCancle,
        form: {
          password: null,
          againPassword: null,
          code: null,
          smsSendBtn: false,
          time: 60,
        },
      },
    }
  },
  mounted() {
    this.userInfo = storage.get('staff_info') || {}
  },
  created() { 
  },
  methods: {
    getCaptcha(e) {
      e.preventDefault()
      let self = this
      const state = self.pwdModal.form
      state.smsSendBtn = true

      const interval = window.setInterval(() => {
        if (state.time-- <= 0) {
          state.time = 60
          state.smsSendBtn = false
          window.clearInterval(interval)
        }
      }, 1000)

      const hide = self.$message.loading('验证码发送中..', 0)
      getpsSmsCode({ mobile: self.userInfo.staff_mobile, type: 6 })
        .then((res) => {
          setTimeout(() => {
            hide()
          }, 300)
        })
        .catch((err) => {
          setTimeout(hide, 1)
          clearInterval(interval)
          state.time = 60
          state.smsSendBtn = false
        })
    },
    initModal({ isFirst = false, userInfo = {} }) {
      this.pwdModal.form = {
        password: null,
        againPassword: null,
        code: null,
        smsSendBtn: false,
        time: 60,
      }
      this.isFirst = isFirst
      if (isFirst) {
        this.pwdModal.title = '首次登录请设置密码！'
      } else {
        this.pwdModal.title = '修改密码'
      }
      this.userInfo = userInfo
      this.pwdModal.visible = true
    },
    handlePwdOk(formName) {
      let self = this
      self.$refs[formName].validate((valid) => {
        if (valid) {
          this.handlePwdOk_(formName)
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handlePwdOk_(formName) {
      let self = this
      const { password, againPassword, code } = self.pwdModal.form
      let data = {}
      self.pwdModal.confirmLoading = true
      if (self.isFirst) {
        data = { password, againPassword }
        FirstmodifyPassword(data)
          .then((res) => {
            if (res.status !== 'success') {
              return
            }
            self.pwdModal.visible = false
            // self.$notification['success']({
            //   message: '成功',
            //   description: '您已修改密码，请重新登录',
            // })
            // store.dispatch('Logout').then(() => {
            //   router.push({ path: '/login/login',query: {tabs:'tab1'} })
            // })
          })
          .finally((r) => {
            self.pwdModal.confirmLoading = false
          })
      } else {
        let phone = self.userInfo.staff_mobile
        data = { password, againPassword, code, phone }
        modifyPassword(data)
          .then((res) => {
            if (res.status !== 'success') {
              return
            }
            self.pwdModal.visible = false
            // self.$notification['success']({
            //   message: '成功',
            //   description: '您已修改密码，请重新登录',
            // })
            // store.dispatch('Logout').then(() => {
            //   router.push({ path: '/user/login', query: { tabs: 'tab1' } })
            // })
          })
          .finally((r) => {
            self.pwdModal.confirmLoading = false
          })
      }
    },
    handlePwdCancle(formName) {
      let self = this
      self.$refs[formName].resetFields()
      self.pwdModal.visible = false
      self.isFirst &&
        self.$warning({
          title: '提示',
          content: '稍后您也可以点击用户头像，在<修改密码>中设置登录密码！',
        })
      //   self.isFirst&&self.$confirm({
      // icon: self.$Common.generatorIconConfirm(),
      // closable: true,
      //     title: '提示',
      //     content: '稍后您也可以点击用户头像，在<修改密码>中设置登录密码！',
      //     okText: '确定',
      //     okType: 'danger',
      //     cancelText: '取消',
      //     onOk: () => {
      //         self.pwdModal.visible = false
      //     },
      //     onCancel () {}
      //   })
    },
  },

}
</script>

<style lang="less" scoped>
.ant-input-handpe-pwd {
  // box-shadow: inset 0px 3px 0px 0px rgba(194, 203, 215, 0.19);
  // border: none;
  border: 1px solid #c4c7d9;
  // width: 702px;
  // & /deep/ input {
  //   width: 525px;
  //   border: 1px solid #C4C7D9;
  // }
}
.getCaptcha {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  display: inline-flex;
  align-items: center;
  padding: 0 8px;
  height: 16px;
  border-left: 1px solid #cecece;
  text-align: center;
  // color: #33323F;
  color: @baseColor11;

  // &:hover {
  //   color: @primary-color;
  // }
}
/deep/ .ant-input-affix-wrapper .ant-input-suffix {
  color: #9fa2af;
}
/deep/ .ant-input-password-icon:hover {
  color: @primaryColor;
}
/deep/ .ant-input-password-icon:active {
  color: @primaryColor;
}
.ant-modal-body .ant-form-item {
  margin-bottom: 10px;
}
/deep/.ant-form-item-label {
  line-height: 32px;
  text-align: left;
}
/deep/ .ant-form-item-control {
  line-height: 32px;
}
/deep/ .ant-form-item-required::after {
  display: inline-block;
  margin-right: 4px;
  color: #ff2222;
  font-size: 12px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
/deep/ .ant-form-item-label > label::before {
  content: "";
}
/deep/ .ant-modal-footer {
  border-top: 0;
  padding: 6px 24px 32px 24px;
}
// /deep/ .ant-modal-centered .ant-modal {
//   background: #d8dbec;
//   box-shadow: 0px 2px 24px 0px rgb(0 0 0 / 8%);
//   padding-bottom: 4px;
//   border-radius: 4px;
// }
/deep/ .ant-modal-content {
  box-shadow: black;
  .anticon-close {
    padding: 5px;
  }
  .anticon-close:focus {
    background-color: #ebecf2;
  }
  .ant-modal-close:hover {
    color: #666666;
  }
  .anticon-close:hover {
    color: #666666;
    background-color: #f0f0f0;
  }
}
</style>
